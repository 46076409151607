import React, { ReactElement } from 'react'
import { Flex, Text } from '@theme-ui/components'

export const resolverText = {
  resolverId: 'Resolver ID',
  legacy: 'Legacy DNS',
  doh: 'DNS-over-HTTPS/3',
  dot: 'DNS-over-TLS/DoQ',
  secure: 'Secure DNS',
}

type ResolverTabTitleProps = {
  type: string
  isOpen?: boolean
}
export default function ResolverTabTitle({ type }: ResolverTabTitleProps): ReactElement {
  return (
    <Flex
      sx={{
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Text
        variant="cardLabel"
        sx={{
          color: 'labelColor',
          fontSize: ['1.6rem', '1.8rem'],
        }}
      >
        {resolverText[type]}
      </Text>
      <Text
        variant="subText"
        sx={{
          color: 'subTextColor',
          fontSize: ['1.4rem', '1.6rem'],
        }}
      >
        {type === 'legacy' ? 'Unencrypted' : 'Encrypted'}
      </Text>
    </Flex>
  )
}
