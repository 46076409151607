import React, { ReactElement } from 'react'
import { useSendMessageMutation } from 'store/api/barry/barry'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { removeMessage, sendMessage } from 'store/barry/barry'
import { Flex } from 'theme-ui'
import { Button } from 'ui'
import ChatBubble from './ChatBubble'

export default function OutboundChatBubble({
  text,
  userName,
  time,
  hasErrored,
  timestamp,
  errorMessage,
}: {
  text: string
  userName: string
  time?: string
  hasErrored: boolean
  timestamp: number
  errorMessage?: string
}): ReactElement {
  const dispatch = useAppDispatch()
  const [, sendMessageResponse] = useSendMessageMutation({
    fixedCacheKey: 'sendMessage',
  })
  const sessionToken = useAppSelector(s => s.persistData.sessionToken)

  return (
    <>
      <ChatBubble
        dataTestId="outbound-bubble"
        text={text}
        userName={userName}
        time={time}
        hasErrored={hasErrored}
        bubbleSx={{
          borderRadius: '12px',
          backgroundColor: 'midnightGreen',
          boxShadow: '0px 1px 0.2px 0px rgba(255, 255, 255, 0.1) inset;',
          zIndex: 'zIndex2',
          '::before': {
            backgroundColor: 'smokyBlack',
            zIndex: 'zIndex1',
          },
        }}
        containerSx={{ alignItems: 'flex-end' }}
        showAsMarkdown={false}
      />
      {hasErrored && (
        <Flex
          sx={{
            justifyContent: 'end',
            mr: '1.6rem',
            my: '0.8rem',
            fontSize: '1.2rem',
            fontWeight: 'bold',
          }}
        >
          <Button
            aria-label="retry message"
            variant="transparent"
            color="orangeRed"
            onClick={() => {
              dispatch(sendMessage({ messageToSend: text, sessionToken }))
              dispatch(removeMessage(timestamp))
            }}
            data-testid="message-retry"
            sx={{
              ':disabled': {
                backgroundColor: 'orangeRed10',
              },
              fontWeight: 'bold',
            }}
            disabled={sendMessageResponse.isLoading}
          >
            {errorMessage}
          </Button>
        </Flex>
      )}
    </>
  )
}
