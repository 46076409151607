import React, { useRef, useState, ReactElement, useMemo, useEffect } from 'react'
import { Flex, Text } from 'theme-ui'
import { DOCS_CONTROL_D_DOMAIN } from 'gatsby-env-variables'
import {
  Dropdown,
  SimpleActionMenu,
  SimpleActionMenuItemProps,
  Svg,
  useTrayPresenter,
  TrayRenderProps,
  IconButtonContent,
} from 'ui'
import LogoIcon from 'images/dashboard/navBar/logo-icon.svg'
import VerticalLogoIcon from 'images/dashboard/navBar/vertical-logo-icon.svg'
import GreenCheck from 'images/dashboard/navBar/green-check.svg'
import ControlDVersionAndDateInfo from 'components/Dashboard/Profiles/ControlDVersionAndDateInfo'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import useBoundaryElement from 'utils/useBoundaryElement'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { SetupGuideIntermediateStates, SetupOs } from 'components/SetupGuide/SetupGuide.interface'
import useQueryString from 'utils/useQueryString'
import ArrowIcon from 'images/dashboard/arrow-right.svg'
import NotificationIcon from 'images/profileManagement/notification-icon-white.svg'
import ConfigureIcon from 'images/menus/configure-icon.svg'
import NotificationsTray from 'components/Dashboard/NavBar/NotificationTray'
import ExternalLink from 'ui/ExternalLink/ExternalLink.base'
import { MenuItem } from 'components/Dashboard/NavBar/NavBarHeader/MenuItem'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'
import { setIsMobileNavMenuOpen } from 'store/dashboard/dashboard'
import { noRedirectHomePagePath } from 'layouts'
import { useDetectDeviceQuery } from 'store/api/devices'
import { setIsBarryOpen } from 'store/barry/barry'

export default function LogoMenu(): ReactElement {
  const { nav, qs, relocate } = useQueryString()
  const [isOpen, setIsOpen] = useState(false)
  const hideRef = useRef<() => void>()
  const breakpoint = useBreakpointIndex()
  const isMobile = breakpoint === 0
  const isTablet = breakpoint === 1
  const boundaryElement = useBoundaryElement(isMobile ? 'nav-tray-header' : 'nav-menu-left')
  const isNavBarExpanded = useAppSelector(s => s.dashboard.isNavBarExpanded)
  const configuredDevice = useAppSelector(s => s.devices.configuredDevice)
  const isVerticalIcon = !isNavBarExpanded || isTablet
  const logoIcon = isVerticalIcon ? VerticalLogoIcon : LogoIcon
  const newNotificationKeys = useAppSelector(s => s.notifications.newNotificationKeys)
  const dispatch = useAppDispatch()
  const { data: detectDeviceData } = useDetectDeviceQuery('')

  const notificationTray = useMemo(
    () => ({
      id: `NotificationTray + ${breakpoint}`,
      renderTray: (props: TrayRenderProps) => <NotificationsTray {...props} />,
      zIndexName: 'zIndex550',
    }),
    [breakpoint],
  )

  const { showTray } = useTrayPresenter(notificationTray)

  useEffect(() => {
    if (newNotificationKeys?.length) {
      showTray()
    }
  }, [newNotificationKeys?.length, showTray])

  const configureAndNotificationItems: SimpleActionMenuItemProps[] = [
    {
      ...(detectDeviceData && configuredDevice?.PK
        ? {
            children: (
              <IconButtonContent
                dataTestId="configure-device-item"
                startIcon={!configuredDevice ? ConfigureIcon : undefined}
                endIcon={ArrowIcon}
                sxFrontIcon={{
                  svg: {
                    opacity: 1,
                  },
                }}
                fixedBackIconSize={true}
              >
                {configuredDevice ? (
                  <Flex>
                    <TextWithOverFlowAndTippyPopup
                      sxTooltipContent={{ mx: '0.8rem' }}
                      content={configuredDevice.name}
                    />
                  </Flex>
                ) : (
                  <Text
                    className="configure-text"
                    sx={{
                      color: 'pomegranate',
                    }}
                  >
                    Configure!
                  </Text>
                )}
              </IconButtonContent>
            ),
            onClick: () => {
              dispatch(setIsMobileNavMenuOpen?.(false))

              if (configuredDevice) {
                return nav({
                  ...qs,
                  helpPane: SetupGuideIntermediateStates.DNS,
                  setupOs: configuredDevice.icon as SetupOs, // can be SetupOS or something new that has not been added to the SetupOs
                  deviceId: configuredDevice.PK,
                })
              }
              relocate(`/dashboard/endpoints`)
            },
          }
        : {}),
    },
    {
      children: (
        <IconButtonContent startIcon={NotificationIcon} dataTestId={'notifications-button'}>
          <Flex
            sx={{
              justifyContent: 'space-between',
              width: '100%',
              pr: '1rem',
            }}
          >
            <Text>Notifications</Text>
            {newNotificationKeys && newNotificationKeys.length > 0 && (
              <Text
                sx={{
                  color: 'greenApple',
                }}
              >
                {newNotificationKeys.length > 99 ? '99+' : newNotificationKeys.length}
              </Text>
            )}
          </Flex>
        </IconButtonContent>
      ),
      onClick: () => {
        showTray()
      },
    },
  ]

  const helpMenuItems: SimpleActionMenuItemProps[] = [
    {
      isTopDivider: true,
      children: <MenuItem text="Contact Us" to={'/contact'} />,
    },
    {
      children: (
        <IconButtonContent dataTestId={'barry-button'}>
          <Flex
            sx={{
              width: '100%',
              pr: '1rem',
            }}
          >
            <Text>Talk to Barry</Text>
          </Flex>
        </IconButtonContent>
      ),
      onClick: () => {
        dispatch(setIsBarryOpen(true))
      },
    },
    {
      children: <MenuItem text="Docs" to={`${DOCS_CONTROL_D_DOMAIN}/docs`} />,
    },
    {
      children: (
        <MenuItem text="Upcoming Feature" to={`${DOCS_CONTROL_D_DOMAIN}/discuss?tag=feature`} />
      ),
    },
  ]

  const goHomeAndStatusItems: SimpleActionMenuItemProps[] = [
    {
      isTopDivider: true,
      children: <MenuItem text="Go Home" to={noRedirectHomePagePath} />,
    },
    {
      children: <MenuItem text="Status" to={'/status'} />,
    },
  ]

  return (
    <Dropdown
      data-testid={`logo-menu-dropdown-button${
        !!configuredDevice || !!detectDeviceData ? '-configured' : ''
      }`}
      aria-label="logo menu"
      variant="transparent"
      sx={{
        p: 0,
        flexShrink: 0,
      }}
      tippyprops={{
        appendTo: !isNavBarExpanded || isTablet ? 'parent' : boundaryElement,
        animation: 'fade',
        onHide: () => {
          setIsOpen(false)
        },
        onShow: () => {
          setIsOpen(true)
        },
        onCreate: instance => {
          hideRef.current = () => {
            instance.hide()
          }
        },
        offset: [0, 18],
        theme: 'screen',
        placement: 'bottom-start',
        maxWidth: '100%',
      }}
      dropdowncontent={
        <>
          <Flex
            sx={{
              width: ['100%', '28rem'],
              flexDirection: 'column',
              backgroundColor: 'darkItemBG',
              borderRadius: '1.6rem',
              pt: '1.2rem',
              '& hr': { mx: '-1.2rem' },
            }}
          >
            <SimpleActionMenu
              hideRef={hideRef}
              items={[
                ...configureAndNotificationItems,
                ...(isTablet || !isNavBarExpanded ? helpMenuItems : []),
                ...goHomeAndStatusItems,
              ]}
              sxContainer={{ pt: 0, px: '1.2rem', pb: '1.6rem' }}
              sxButton={{
                maxHeight: 'auto',
                borderRadius: '0.8rem',
                px: '1.6rem',
                m: 0,
              }}
            />
          </Flex>
          <Flex
            sx={{
              mb: '1.6rem',
              pt: '2.4rem',
              px: '2.4rem',
              pb: '0.8rem',
              color: 'white50',
              justifyContent: 'space-around',
              fontSize: '1.4rem',
              flexDirection: 'column',
              borderTop: '1px solid',
              borderColor: 'white15',
              lineHeight: '2.2rem',
            }}
          >
            <ControlDVersionAndDateInfo />
            <Text sx={{ textDecoration: 'underline', fontSize: '14px', pt: '1.2rem' }}>
              <ExternalLink
                sx={{
                  color: 'white50',
                }}
                to={`${DOCS_CONTROL_D_DOMAIN}/changelog`}
              >
                View Changelog
              </ExternalLink>
            </Text>
          </Flex>
        </>
      }
    >
      <Flex sx={{ width: isVerticalIcon ? '4.8rem' : '7rem', flexShrink: 0, position: 'relative' }}>
        <Svg svg={logoIcon} fill={isOpen ? 'white' : 'white50'} sx={{ flexShrink: 0 }} />
        {(detectDeviceData || configuredDevice) && (
          <Flex
            sx={{
              position: 'absolute',
              right: isVerticalIcon ? '-0.4rem' : '1.6rem',
              bottom: isVerticalIcon ? '1.4rem' : '-1rem',
              width: 'fit-content',
              height: 'fit-content',
              p: '0.2rem',
              backgroundColor: isMobile ? 'grayLogoBG' : 'darkBodyBG',
              borderRadius: '50%',
            }}
          >
            <Svg svg={GreenCheck} />
          </Flex>
        )}
        {!!newNotificationKeys?.length && (
          <Flex
            sx={{
              position: 'absolute',
              left: '-0.7rem',
              top: isVerticalIcon ? '-0.1rem' : '-0.9rem',
              borderRadius: '2.6rem',
              backgroundColor: isMobile ? 'grayLogoBG' : 'darkBodyBG',
            }}
          >
            <Flex
              data-testid="notifications-count"
              sx={{
                width: '2rem',
                height: '1.5rem',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '8px',
                backgroundColor: 'greenApple15',
                fontWeight: 'bold',
                color: 'greenApple',
                borderRadius: '2.6rem',
                m: '0.2rem',
              }}
            >
              {newNotificationKeys?.length > 99 ? '99+' : '' + newNotificationKeys?.length}
            </Flex>
          </Flex>
        )}
      </Flex>
    </Dropdown>
  )
}
