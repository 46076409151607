import React, { ReactElement } from 'react'
import { useAppDispatch } from 'store/hooks'
import { Page, switchPage } from 'store/barry/barry'
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui'
import { Button } from 'ui'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import remarkGfm from 'remark-gfm'

export interface BubbleProps {
  text?: string
  userName?: string
  time?: string
  bubbleSx?: ThemeUIStyleObject
  containerSx?: ThemeUIStyleObject
  needsHuman?: boolean
  hasErrored?: boolean
  showAsMarkdown?: boolean
  dataTestId: string
}

export default function ChatBubble(props: BubbleProps): ReactElement {
  const {
    text,
    userName,
    time,
    hasErrored,
    bubbleSx,
    containerSx,
    needsHuman,
    dataTestId,
    showAsMarkdown,
  } = props

  const dispatch = useAppDispatch()

  return (
    <Flex
      data-testid={dataTestId}
      sx={{
        width: '100%',
        height: 'min-content',
        flexDirection: 'column',
        px: '1.6rem',
        overflowWrap: 'anywhere',
        overflow: 'hidden',
        ...containerSx,
      }}
    >
      <Text
        variant="size13Weight700"
        sx={{
          color: 'title70',
          marginBottom: '0.8rem',
        }}
      >
        {userName}
      </Text>
      <Flex
        sx={{
          maxWidth: '90%',
          px: '2.0rem',
          py: '1.6rem',
          borderRadius: '2.4rem',
          flexDirection: 'column',
          gap: '0.8rem',
          ...bubbleSx,
        }}
      >
        <Flex
          data-testid="chat-bubble-text"
          sx={{
            fontSize: '1.3rem',
            flexDirection: 'column',
            color: 'lightCyan',
            p: { margin: 0, py: '0.5rem' },
            a: { color: 'white50' },
            width: '100%',
            img: {
              width: '100%',
              height: '100%',
            },
            ol: {
              p: '0rem 1.6rem',
              li: {
                my: '0.8re,',
                pl: '0rem',
                ul: {
                  pl: '1.6rem',
                },
              },
            },
            ul: {
              p: '0rem 1.6rem',
              li: {
                my: '0.8rem',
                pl: '0rem',
                ul: {
                  pl: '1.6rem',
                },
              },
            },
            pre: {
              whiteSpace: 'break-spaces',
              wordBreak: 'break-all',
            },
            code: {
              display: 'inline-block',
              maxWidth: '100%',
              fontSize: '1.0rem',
              backgroundColor: 'blue70',
              padding: '0.5rem',
              borderRadius: '0.5rem',
            },
          }}
        >
          {showAsMarkdown ? (
            <ReactMarkdown rehypePlugins={[remarkGfm]} linkTarget="_blank">
              {text ?? ''}
            </ReactMarkdown>
          ) : (
            text
          )}
        </Flex>
        {needsHuman && (
          <Button
            onClick={() => {
              dispatch(switchPage(Page.SUBMIT_TICKET))
            }}
            ariaLabel="talk to a human button"
            variant="gradientButton"
            data-testid="need-human-button"
            sx={{
              height: '4.7rem',
              width: ['100%', '100%'],
              fontWeight: 600,
            }}
          >
            Talk to a human
          </Button>
        )}
      </Flex>
      {!hasErrored && (
        <Text variant="size12Weight400" sx={{ color: 'lightCyan50', marginTop: '0.8rem' }}>
          {time}
        </Text>
      )}
    </Flex>
  )
}
