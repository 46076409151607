import React, { ReactElement, useState } from 'react'
import { useEndConversationMutation } from 'store/api/barry/barry'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { Page, resetMessageState, switchPage } from 'store/barry/barry'
import { Flex, Text } from 'theme-ui'
import { Button } from 'ui'
import ChatWindow from './BarryChatComponents/ChatWindow'

export default function BarryChatScreen({
  handleBarryIconToggle,
}: {
  handleBarryIconToggle: () => void
}): ReactElement {
  const dispatch = useAppDispatch()
  const [isEndOptionOpen, setIsEndOptionOpen] = useState(false)
  const [reason, setReason] = useState<'endChat' | 'talkToHuman' | undefined>(undefined)
  const { needsHuman, conversationId } = useAppSelector(x => x.barry.message)
  const [endConversationRequest] = useEndConversationMutation()
  const forceEndConversation = useAppSelector(x => x.barry.offence.forceEndConversation)

  const handleClose = () => {
    setIsEndOptionOpen(true)
    setReason('endChat')
    if (forceEndConversation) {
      dispatch(resetMessageState())
      endConversationRequest({ conversationId })
      handleBarryIconToggle
      dispatch(switchPage(Page.SPLASH_PAGE))
    }
  }

  return (
    <Flex
      data-testid="chat-screen"
      sx={{
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: ['0rem', '2.4rem'],
      }}
    >
      <ChatWindow />
      <Flex
        sx={{
          width: '100%',
          height: forceEndConversation ? '4.8rem' : '9.6rem',
        }}
      >
        {!isEndOptionOpen && (
          <Flex
            sx={{
              width: '100%',
              borderRadius: [0, '0 0 2.2rem 2.2rem'],
              alignItems: 'flex-end',
            }}
          >
            <Button variant="barryChatAction" data-testid="end-chat-button" onClick={handleClose}>
              End Chat
            </Button>
            {needsHuman && (
              <Button
                variant="barryChatAction"
                data-testid="escalate-to-human-button"
                onClick={() => {
                  setIsEndOptionOpen(true)
                  setReason('talkToHuman')
                }}
                sx={{
                  borderLeft: '1px solid',
                  borderLeftColor: 'mattBlue30',
                  color: 'orangeRed',
                }}
              >
                Escalate to human!
              </Button>
            )}
          </Flex>
        )}
        {isEndOptionOpen && (
          <Flex
            sx={{
              width: '100%',
              backgroundColor: 'darkJungleGreen',
              height: '4.8rem',
              zIndex: 'zIndex2',
              justifyContent: 'space-between',
              alignSelf: 'flex-end',
              fontSize: '1.4rem',
              pt: '1.2rem',
              px: '1.6rem',
              color: 'white',
            }}
          >
            <Text
              variant="size15Weight400Line138"
              sx={{
                mt: '0.4rem',
                color: 'body',
              }}
            >
              Are you sure?
            </Text>
            <Flex sx={{ gap: '1.2rem' }}>
              <Button
                data-testid="end-chat-yes"
                variant="roundedSecondary"
                sx={{ height: '2.8rem', width: '7.4rem' }}
                onClick={() => {
                  setIsEndOptionOpen(false)
                  dispatch(
                    switchPage(reason === 'talkToHuman' ? Page.SUBMIT_TICKET : Page.SPLASH_PAGE),
                  )
                  if (reason === 'endChat') {
                    dispatch(resetMessageState())
                    endConversationRequest({ conversationId })
                    handleBarryIconToggle
                  }
                }}
              >
                <Text variant="size15Weight700Line138">Yes</Text>
              </Button>
              <Button
                data-testid="end-chat-no"
                variant="roundedSecondary"
                sx={{ height: '2.8rem', width: '7.4rem' }}
                onClick={() => setIsEndOptionOpen(false)}
              >
                <Text variant="size15Weight700Line138">No</Text>
              </Button>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
