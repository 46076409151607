import React, { createRef, ReactElement, ReactNode, useState } from 'react'
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui'
import { CONTROLDText } from 'ui/CONTROLD'
import { Button, Svg } from 'ui'
import ArrowRightIcon from 'images/dashboard/arrow-right.svg'
import { useAppSelector } from 'store/hooks'
import { useLocation } from '@reach/router'
import useQueryString from 'utils/useQueryString'
import useShouldRenderOnClient from 'utils/useShouldRenderOnClient'
import useGetUserState from 'store/api/user/useGetUserState'
import { Setter } from 'utils'
import CloseIcon from 'images/menu-close.svg'
import { useGetBillingProductsQuery } from 'store/api/billing'
import { navigate } from 'gatsby'
import { differenceInDays } from 'date-fns'
import moment from 'moment/moment'
import useGetBillingAndSubscriptions from 'components/Organization/OrganizationBilling/useGetBillingAndSubscriptions'
import { OrgPermissionLevel } from 'store/api/organization/organization.interface'
import { isBillingMethodValidToDisplayBanner } from './Organization/OrganizationBilling/BillingPreferences'
import { organizationApi } from 'store/api/organization'
import { userApi } from 'store/api/user'

export const warningMessageRef = createRef<HTMLDivElement>()

const supportMessageHeight = ['9.2rem', '5.8rem']

const warningMessage = `The version of iOS/Safari you are using is outdated and may cause problems viewing the
      website. Please upgrade them to ensure optimal experience.`

export function WarningMessage(): ReactElement | null {
  const isOnboardingCompleted = useAppSelector(s => s.session.isOnboardingCompleted)
  const sessionToken = useAppSelector(s => s.persistData.sessionToken)
  const warningError = useAppSelector(s => s.products.warningError)
  const { isOrganization } = useGetUserState()
  const { data: userData } = userApi.endpoints.getUser.useQueryState('')
  const permissionLevel = userData?.org?.permission?.level
  const isViewer = permissionLevel === OrgPermissionLevel.VIEWER
  const [showWarningMessage, setShowWarningMessage] = useState(true)
  const { areSubscriptionsLoading, billingSubscription } = useGetBillingAndSubscriptions(
    !sessionToken || !isOrganization,
  )
  const {
    data: orgData,
    isLoading: isOrganizationLoading,
    isUninitialized,
  } = organizationApi.endpoints.getOrganization.useQueryState('')

  useGetBillingProductsQuery('', { skip: !sessionToken })

  const shouldRenderOnClient = useShouldRenderOnClient()

  if (!showWarningMessage) {
    return null
  }

  if (!shouldRenderOnClient) {
    return null
  }

  if (
    !areSubscriptionsLoading &&
    !isOrganizationLoading &&
    !isUninitialized &&
    userData &&
    isOrganization &&
    !isViewer &&
    isBillingMethodValidToDisplayBanner(orgData?.organization?.billing_method) &&
    orgData?.organization?.status_printed === 'Trial' &&
    !['cancelled', 'active', 'failed'].includes(billingSubscription?.state || '') &&
    differenceInDays(moment(orgData?.organization?.trial_end).toDate(), moment().toDate()) <= 23
  ) {
    return <TrialExpiresContent />
  }

  if (
    isOrganization &&
    !isViewer &&
    billingSubscription?.state === 'failed' &&
    !areSubscriptionsLoading &&
    !isOrganizationLoading
  ) {
    return <RebillFailedMessageContent />
  }

  if (isOrganization) {
    return null
  }

  if (warningError) {
    return (
      <FailedBillingMessageContent
        message={warningError.message}
        path={warningError?.actions?.[0].path}
        setShowWarningMessage={setShowWarningMessage}
      />
    )
  }

  return isOnboardingCompleted === undefined || isOnboardingCompleted ? (
    <SupportMessageContent />
  ) : (
    <ConfigureDeviceContent />
  )
}

function WarningMessageWrapper({ children, sx }: { children: ReactNode; sx?: ThemeUIStyleObject }) {
  return (
    <Flex
      data-testid="warning-message"
      ref={warningMessageRef}
      sx={{
        position: 'sticky',
        top: 0,
        width: '100%',
        height: 0,
        px: '1.6rem',
        fontSize: ['1.4rem', '1.6rem'],
        fontWeight: 'bold',
        backgroundColor: 'pomegranate',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        zIndex: 'zIndex0',
        ...sx,
      }}
      className="support-message"
    >
      {children}
    </Flex>
  )
}

function ConfigureDeviceContent(): ReactElement | null {
  const location = useLocation()
  const { relocate } = useQueryString()
  if (!location.pathname.includes('dashboard')) {
    return null
  }

  return (
    <WarningMessageWrapper sx={{ height: '4.2rem' }}>
      <Button
        variant="transparent"
        sx={{
          width: ['100%', 'auto'],
          p: 0,
          my: '0.8rem',
          justifyContent: ['space-between', 'center'],
        }}
        onClick={() => relocate('/dashboard/endpoints')}
      >
        <Text sx={{ fontSize: ['1.6rem', '1.8rem'], fontWeight: 'bold', mr: '0.8rem' }}>
          Configure Endpoint to activate {CONTROLDText}
        </Text>
        <Svg svg={ArrowRightIcon} />
      </Button>
    </WarningMessageWrapper>
  )
}

function SupportMessageContent(): ReactElement {
  return (
    <WarningMessageWrapper
      sx={{
        height: supportMessageHeight,
        //  * (gap: 1rem) will be a false positive if the browser supports gap with grid
        //  * @supports selector() entered browsers at roughly the same point as flexbox gap,
        //  *  which means it can be used as a rough proxy for support for flex gap.
        //  * */
        '@supports selector(:first-child)': {
          display: 'none',
        },
      }}
    >
      {warningMessage}
    </WarningMessageWrapper>
  )
}

function FailedBillingMessageContent({
  message,
  path,
  setShowWarningMessage,
}: {
  message: string
  path: string
  setShowWarningMessage: Setter<boolean>
}): ReactElement | null {
  const { relocate } = useQueryString()

  if (!location.pathname.includes('dashboard')) {
    return null
  }

  return (
    <WarningMessageWrapper
      sx={{
        height: supportMessageHeight,
      }}
    >
      <Button
        variant="transparent"
        sx={{
          width: ['94%', 'auto'],
          p: 0,
          my: '0.8rem',
          justifyContent: ['space-between', 'center'],
        }}
        onClick={() => relocate(path)}
      >
        <Text sx={{ fontSize: ['1.4rem', '1.6rem'], fontWeight: 'bold', mr: '0.8rem' }}>
          {message}
        </Text>
        <Svg svg={ArrowRightIcon} />
      </Button>
      <Button
        data-testid="close-warning-message"
        variant="simple"
        sx={{
          position: 'absolute',
          right: '1.6rem',
          alignSelf: 'center',
          p: 0,
          m: 0,
        }}
        onClick={() => setShowWarningMessage(false)}
      >
        <Svg svg={CloseIcon} />
      </Button>
    </WarningMessageWrapper>
  )
}

function TrialExpiresContent(): ReactElement | null {
  const { qs } = useQueryString()
  const location = useLocation()

  if (!location.pathname.includes('dashboard')) {
    return null
  }

  return (
    <WarningMessageWrapper
      sx={{
        display: qs.orgId ? 'none' : 'flex',
        height: ['7.2rem', '4.8rem', '4.2rem'],
      }}
    >
      <Text sx={{ fontSize: ['1.4rem', '1.6rem'], fontWeight: 'bold', mr: '0.8rem' }}>
        You are using a {CONTROLDText} trial. Please add a payment method to keep using{' '}
        {CONTROLDText}.{' '}
        <Button
          variant="transparent"
          sx={{
            display: 'inline-flex',
            textDecoration: 'underline',
            p: 0,
            fontSize: ['1.4rem', '1.6rem'],
            fontWeight: 'bold',
          }}
          onClick={() => navigate('/change-payment-method')}
        >
          Add Payment Method
        </Button>
      </Text>
    </WarningMessageWrapper>
  )
}

function RebillFailedMessageContent(): ReactElement | null {
  const { qs } = useQueryString()
  const location = useLocation()

  if (!location.pathname.includes('dashboard')) {
    return null
  }

  return (
    <WarningMessageWrapper
      sx={{
        display: qs.orgId ? 'none' : 'flex',
        height: ['7.2rem', '4.8rem', '4.2rem'],
      }}
    >
      <Text sx={{ fontSize: ['1.4rem', '1.6rem'], fontWeight: 'bold', mr: '0.8rem' }}>
        We were unable to process your payment. Please update your credit card information.{' '}
        <Button
          variant="transparent"
          sx={{
            display: 'inline-flex',
            textDecoration: 'underline',
            p: 0,
            fontSize: ['1.4rem', '1.6rem'],
            fontWeight: 'bold',
          }}
          onClick={() => navigate('/change-payment-method')}
        >
          Change Credit Card
        </Button>
      </Text>
    </WarningMessageWrapper>
  )
}
