import React, { ReactElement } from 'react'
import { Flex } from 'theme-ui'
import { Input } from 'ui'

const SupportTicketInput = ({
  label,
  inputTestId,
  onChange,
  value,
  name,
  required,
  type,
}: {
  label: string
  inputTestId: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  value: string
  name: string
  required?: boolean
  type: string
}): ReactElement => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        gap: '0.8rem',
      }}
    >
      <Flex>
        <Input
          label={label}
          backgroundColor="smokyBlack"
          activeLabelColor="white"
          data-testid={inputTestId}
          name={name}
          value={value}
          labelColor="title"
          color="white"
          required={required}
          onChange={onChange}
          type={type}
          shouldUseNewDesign={true}
          containerStyle={{
            borderRadius: '1.2rem',
          }}
          sx={{
            /*The upcoming feature UI overhaul has restructured the the Input.base component, which will allow the
            following style to be included in variant. This is acts as a placeholder solution until feature UI overhaul
            is merged into master*/
            height: '4.8rem',
            border: '1px solid',
            borderRadius: '1.2rem',
            borderColor: 'borderLight',
            backgroundColor: 'green',
            mx: 0,
            px: '1.6rem',
            fontSize: '1.5rem',
            caretColor: 'cyan800',
            '&:hover': {
              ':not([disabled])': {
                ':not(:focus)': {
                  borderColor: 'mattBlue90',
                },
              },
            },
            '&:focus': {
              borderColor: 'cyan800',
            },
          }}
        />
      </Flex>
    </Flex>
  )
}

export default SupportTicketInput
