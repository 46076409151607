import React, { ReactElement } from 'react'
import { useAppDispatch } from 'store/hooks'
import { Flex, Text } from 'theme-ui'
import { Button } from 'ui'
import BarryIconBackground from 'images/barry/barry-background.svg'
import TicketSentIcon from 'images/barry/ticket-sent.svg'
import { clearPageState, resetMessageState } from 'store/barry/barry'

export default function TicketSent({
  handleBarryIconToggle,
}: {
  handleBarryIconToggle: () => void
}): ReactElement {
  const dispatch = useAppDispatch()

  const handleSubmit = () => {
    dispatch(resetMessageState())
    dispatch(clearPageState())
    handleBarryIconToggle()
  }

  return (
    <>
      <Flex
        sx={{
          zIndex: 'zIndex0',
          position: 'absolute',
          pointerEvents: 'none',
          top: '-18.0rem',
        }}
      >
        <BarryIconBackground />
      </Flex>
      <Flex
        data-testid="ticket-sent-view"
        sx={{
          width: '100%',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <TicketSentIcon
          sx={{
            zIndex: 'zIndex1',
          }}
        />
        <Text
          variant="size28Weight600"
          sx={{
            color: 'title',
            marginBottom: '0.8rem',
            pt: '1.1rem',
          }}
        >
          Ticket Sent!
        </Text>
        <Flex
          sx={{
            color: 'body',
            marginBottom: '2.4rem',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Text variant="size15Weight400Line138" sx={{ textAlign: 'center' }}>
            Our support team will get back to you shortly.
          </Text>
        </Flex>
        <Button
          ariaLabel="close"
          variant="gradientButton"
          data-testid="splash-screen-button"
          onClick={handleSubmit}
          sx={{ px: '2rem', py: '1.2rem', width: 'auto' }}
        >
          <Text variant="size18Weight700">Close</Text>
        </Button>
      </Flex>
    </>
  )
}
