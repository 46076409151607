import { combineReducers } from '@reduxjs/toolkit'
import { sessionSlice } from './session'
import { persistSlice } from './persist'
import storage from './storage'
import {
  billingPaymentsSlice,
  productsSlice,
  paymentsSlice,
  trialEligibilitySlice,
} from './billing'
import { twofaSlice } from './twofa'
import { defaultRuleSlice } from './defaultRule'
import { errorsSlice } from './errors'
import { servicesSlice } from './services'
import { filtersSlice } from './filters'
import { customRulesSlice, groupsSlice, ruleTraySlice } from './customRules'
import { proxyTraySlice } from 'store/proxyTray'
import { profileSlice, deviceSlice } from 'store/multiprofile'
import { notificationsSlice } from 'store/notifications'
import { accessSlice } from 'store/access'
import { tutorialSlice } from 'store/tutorial/tutorial'
import { freeDNSSlice } from 'store/freeDNS'
import { createTransform, persistReducer } from 'redux-persist'
import { analyticsSlice } from './analytics'
import { featuredProfilesSlice } from 'store/multiprofile/featuredProfiles'
import { migrateSessionsState } from './migrations'
import { trackingSlice } from 'store/tracking'
import { provisionSlice } from 'store/provisioning'
import { activityLogSlice } from 'store/activityLog/activityLog'
import { dashboardSlice } from 'store/dashboard/dashboard'
import { baseApi } from 'store/api'
import { organizationSlice } from 'store/organization/organization'
import { Organization } from 'store/api/organization/organization.interface'
import { adminLogSlice } from './adminLog'
import { barrySlice } from 'store/barry/barry'

const persistTutorialConfig = {
  key: 'tutorial',
  storage,
  migrate: state => {
    return migrateSessionsState(state, 'persist:sessions')
  },
}

const persistBetweenReloadsConfig = {
  // this is called "data" for legacy reasons, as this was the name of the persist
  // key when there was only one config (now we have between-session persistence and
  // between-reload persistence)
  key: 'data',
  storage,
}

const persistNotificationsConfig = {
  key: 'notifications',
  storage,
  whitelist: ['savedNotificationKeysByUserPk'],
}

const persistOrgTransform = createTransform(
  (inboundState, key) => {
    if (key === 'org') {
      return (inboundState as Organization)?.PK
    }
    return inboundState
  },
  (outboundState, key) => {
    if (key === 'org') {
      return { PK: outboundState }
    }
    return outboundState
  },
)
const persistSessionConfig = {
  key: 'session',
  storage,
  transforms: [persistOrgTransform],
  whitelist: ['userPk', 'isOrganization', 'region', 'email'],
}

const persistPaymentsConfig = {
  key: 'payments',
  storage,
  whitelist: ['planStepsCount'],
}

const persistProductsConfig = {
  key: 'products',
  storage,
  whitelist: ['promoMode', 'promoCode', 'selectedProductPk', 'priceInterval', 'selectedCurrency'],
}
const persistServicesConfig = {
  key: 'services',
  storage,
  whitelist: ['showActiveOnly', 'showCardView', 'numberOfServices'],
}

export const persistDefaultRuleConfig = {
  key: 'defaultRule',
  storage,
  whitelist: ['redirectMode', 'lastUsedRedirectLocationPK'],
}

const persistProfilesConfig = {
  key: 'profiles',
  storage,
  whitelist: ['shouldSortProfilesAlphabetically', 'editingProfileSection'],
}

export const persistAnalyticsConfig = {
  key: 'analytics',
  storage,
  whitelist: [
    'verdictTypeFilter',
    'filterParameter',
    'filterType',
    'selectedDeviceId',
    'selectedClientId',
    'selectedPresetTimeRange',
    'timeRange',
    'dropdownsData',
    'filterParameters',
  ],
}
export const persistCustomRulesConfig = {
  key: 'customRules',
  storage,
  whitelist: ['shouldShowLargeView', 'groupUserIsIn'],
}

export const persistTrackingConfig = {
  key: 'experience',
  storage,
}

const persistBarryConfig = {
  key: 'barry',
  storage,
  whitelist: ['message', 'offence', 'pageNumber', 'userExperience'],
}

const persistAccessConfig = {
  key: 'access',
  storage,
  whitelist: ['selectedDeviceId'],
}

const persistActivityLogConfig = {
  key: 'activityLog',
  storage,
  whitelist: ['queryFilters', 'selectedPresetTimeRange'],
}

const persistDashboardConfig = {
  key: 'dashboard',
  storage,
  whitelist: ['breakpointIndex'],
}

const persistDevicesConfig = {
  key: 'devices',
  storage,
  whitelist: ['shouldSortDevicesAlphabetically'],
}

const persistOrganizationsConfig = {
  key: 'organizations',
  storage,
  whitelist: ['selectedSubOrgPk'],
}

const persistProvisioningConfig = {
  key: 'provisioning',
  storage,
  whitelist: ['consumption'],
}

const persistAdminLogConfig = {
  key: 'adminLog',
  storage,
  whitelist: ['logFilters', 'selectedPresetTimeRange', 'initialExternalRegionsParam'],
}

const appReducer = combineReducers({
  twofa: twofaSlice.reducer,
  errors: errorsSlice.reducer,
  groups: groupsSlice.reducer,
  access: persistReducer(persistAccessConfig, accessSlice.reducer),
  devices: persistReducer(persistDevicesConfig, deviceSlice.reducer),
  filters: filtersSlice.reducer,
  freeDNS: freeDNSSlice.reducer,
  profiles: persistReducer(persistProfilesConfig, profileSlice.reducer),
  ruleTray: ruleTraySlice.reducer,
  proxyTray: proxyTraySlice.reducer,
  billingPayments: billingPaymentsSlice.reducer,
  featuredProfiles: featuredProfilesSlice.reducer,
  trialEligibility: trialEligibilitySlice.reducer,
  barry: persistReducer(persistBarryConfig, barrySlice.reducer),
  session: persistReducer(persistSessionConfig, sessionSlice.reducer),
  services: persistReducer(persistServicesConfig, servicesSlice.reducer),
  tutorial: persistReducer(persistTutorialConfig, tutorialSlice.reducer),
  products: persistReducer(persistProductsConfig, productsSlice.reducer),
  payments: persistReducer(persistPaymentsConfig, paymentsSlice.reducer),
  tracking: persistReducer(persistTrackingConfig, trackingSlice.reducer),
  analytics: persistReducer(persistAnalyticsConfig, analyticsSlice.reducer),
  persistData: persistReducer(persistBetweenReloadsConfig, persistSlice.reducer),
  defaultRule: persistReducer(persistDefaultRuleConfig, defaultRuleSlice.reducer),
  customRules: persistReducer(persistCustomRulesConfig, customRulesSlice.reducer),
  notifications: persistReducer(persistNotificationsConfig, notificationsSlice.reducer),
  provisioning: persistReducer(persistProvisioningConfig, provisionSlice.reducer),
  activityLog: persistReducer(persistActivityLogConfig, activityLogSlice.reducer),
  dashboard: persistReducer(persistDashboardConfig, dashboardSlice.reducer),
  organization: persistReducer(persistOrganizationsConfig, organizationSlice.reducer),
  adminLog: persistReducer(persistAdminLogConfig, adminLogSlice.reducer),
  [baseApi.reducerPath]: baseApi.reducer,
})

export type RootState = ReturnType<typeof appReducer>

export default appReducer
