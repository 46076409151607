import React, { ReactElement, useEffect, useState } from 'react'
import { Flex, Text } from 'theme-ui'
import { Button, Svg } from 'ui'
import BarryIcon from 'images/barry/barryicon.svg'
import BarryIconBackground from 'images/barry/barry-background.svg'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { Page, setBan, switchPage, updateTimestamp } from 'store/barry/barry'

const dayInMilliseconds = 1000 * 60 * 60 * 24

function isBanExpired(lastOffenceTimestamp: number | undefined) {
  if (lastOffenceTimestamp === undefined) {
    return true
  }
  const currentTime = Date.now()
  return (currentTime - lastOffenceTimestamp) / dayInMilliseconds > 1
}

function formatTimer(hours: number, minutes: number, seconds: number): string {
  return `${hours > 9 ? hours : `0${hours}`}:${minutes > 9 ? minutes : `0${minutes}`}:${
    seconds > 9 ? seconds : `0${seconds}`
  }`
}

function banTimer(lastOffenceTimestamp: number | undefined) {
  if (lastOffenceTimestamp === undefined) {
    return { hours: 0, minutes: 0, seconds: 0 }
  }
  const currentTime = Date.now()
  const timeLeft = lastOffenceTimestamp + dayInMilliseconds - currentTime
  return {
    hours: Math.floor(timeLeft / (1000 * 3600)),
    minutes: Math.floor((timeLeft % (1000 * 3600)) / (1000 * 60)),
    seconds: Math.floor((timeLeft % (1000 * 60)) / 1000),
  }
}

export default function SplashScreen(): ReactElement {
  const dispatch = useAppDispatch()
  const { isBanned } = useAppSelector(x => x.barry.offence)
  const [banCountdown, setBanCountdown] = useState({ hours: 24, minutes: 0, seconds: 0 })
  const { lastOffenceTimestamp } = useAppSelector(x => x.barry.offence)

  /**
   *  Manages the ban countdown, lifts the ban if expired or completed,
   *  and updates the countdown every second while banned.
   */
  useEffect(() => {
    if (banCountdown.hours < 1 && banCountdown.minutes < 1 && banCountdown.seconds < 1) {
      dispatch(setBan(false))
      dispatch(updateTimestamp(undefined))
      return
    }
    if (isBanExpired(lastOffenceTimestamp)) {
      dispatch(setBan(false))
    }
    if (isBanned === true) {
      const interval = setInterval(() => {
        setBanCountdown(banTimer(lastOffenceTimestamp))
      }, 1000)
      return () => clearInterval(interval)
    }
    return
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [banCountdown])

  return (
    <>
      <Flex
        sx={{
          zIndex: 'zIndex0',
          position: 'absolute',
          top: 0,
          pointerEvents: 'none',
          overflow: 'hidden',
        }}
      >
        <Svg svg={BarryIconBackground} />
      </Flex>
      <Flex
        data-testid="barry-splash-screen"
        sx={{
          width: '100%',
          flexDirection: 'column',
          alignItems: 'center',
          zIndex: 'zIndex1',
        }}
      >
        <Svg svg={BarryIcon} />
        <Text
          sx={{
            pt: '1.6rem',
            color: 'white',
            fontSize: '2.8rem',
            fontWeight: 'bold',
            pb: '0.8rem',
          }}
        >
          Ask Barry
        </Text>
        {!isBanned ? (
          <Flex sx={{ flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            <Flex
              sx={{
                color: 'body',
                marginBottom: '3.2rem',
                flexDirection: 'column',
                alignItems: 'center',
                width: '32rem',
                textAlign: 'center',
              }}
            >
              <Text variant="size15Weight400Line138">
                I can help answer and troubleshoot any question or issue.
              </Text>
            </Flex>
            <Button
              data-testid="splash-screen-button"
              onClick={() => dispatch(switchPage(Page.DESCRIBE_ISSUE_PAGE))}
              variant="gradientButton"
              sx={{
                width: 'fit-content',
                height: '4.4rem',
                py: 0,
              }}
            >
              <Text variant="size17Weight400" sx={{ color: 'white' }}>
                Ask me anything
              </Text>
            </Button>
          </Flex>
        ) : (
          <Flex
            data-testid="banned-splash-view"
            sx={{ flexDirection: 'column', alignItems: 'center', width: '100%' }}
          >
            <Flex
              sx={{
                color: 'body',
                marginBottom: '3.2rem',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                fontSize: '1.5rem',
              }}
            >
              <Text>Sorry, you&apos;re not able to talk to Barry</Text>
              <Text>right now, Please try again in 24 hours.</Text>
            </Flex>

            <Flex
              sx={{
                height: '4.8rem',
                backgroundColor: 'darkJungleGreen',
                borderRadius: '4rem',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Text
                variant="size17Weight700"
                sx={{
                  color: 'title20',
                  width: '100%',
                  px: '2rem',
                  py: '1.2rem',
                }}
              >
                {formatTimer(banCountdown.hours, banCountdown.minutes, banCountdown.seconds)}
              </Text>
            </Flex>
          </Flex>
        )}
      </Flex>
    </>
  )
}
