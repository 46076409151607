import { baseApi, getQueryArgs, transformErrorResponse } from 'store/api'
import {
  AnalyticsPieChartData,
  AnalyticsTimeSeriesData,
  CommonParams,
  MostActiveType,
  TimeGranularity,
  VerdictType,
  StatEndpointInfo,
  StatLevelInfo,
  TrafficType,
} from 'store/api/analytics/analytics.interface'
import { ANALYTICS_BASE_URL } from 'gatsby-env-variables'
import { ApiResponse } from '../http'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react'

const generateQueryParams = ({
  startTs,
  endTs,
  granularity,
  filter,
  service,
  via,
  deviceId,
  clientId,
}: {
  startTs?: number
  endTs?: number
  granularity?: TimeGranularity
  filter?: string
  service?: string
  via?: string
  deviceId?: string
  clientId?: string
}) => {
  const params = new URLSearchParams()

  if (startTs !== undefined) {
    params.append('startTs', `${startTs}`)
  }
  if (endTs !== undefined && new Date(endTs).toDateString() !== new Date().toDateString()) {
    params.append('endTs', `${endTs}`)
  }
  if (granularity) {
    params.append('granularity', granularity)
  }
  if (filter) {
    params.append('filter', filter)
  }
  if (service) {
    params.append('service', service)
  }
  if (via) {
    params.append('via', via)
  }
  if (deviceId) {
    // replacing : with - for analytics BE
    params.append('deviceId', deviceId)
  }
  if (clientId) {
    params.append('clientId', clientId)
  }

  params.append('tz', Intl.DateTimeFormat().resolvedOptions().timeZone)

  return params
}

export const transformStatsErrorResponse = (
  response: FetchBaseQueryError,
): FetchBaseQueryError & { message?: string } => {
  if (response.status === 'FETCH_ERROR') {
    return { ...response, message: 'Analytics backend is not reachable from your network.' }
  }
  return response
}

const TIME_SERIES_PATH = '/reports/dns-queries/all-by-verdict/time-series?'
const FILTERS_PIE_CHART_PATH = '/reports/dns-queries/blocked-by-filter/pie-chart'
const SERVICES_PIE_CHART_PATH = '/reports/dns-queries/service-triggered-by-service/pie-chart'
const LOCATIONS_PIE_CHART_PATH = '/reports/dns-queries/redirected-by-location/pie-chart'
const BLOCKED_BY_DOMAIN_DATA_PATH = '/reports/dns-queries/blocked-by-domain/pie-chart?'
const REDIRECTED_BY_DOMAIN_DATA_PATH = '/reports/dns-queries/redirected-by-domain/pie-chart?'
const BYPASSED_BY_DOMAIN_DATA_PATH = '/reports/dns-queries/bypassed-by-domain/pie-chart?'
const COUNTS_BY_SOURCE_COUNTRY_PATH = '/reports/dns-queries/counts-by-source-country'
const COUNTS_BY_DESTINATION_COUNTRY_PATH = '/reports/dns-queries/counts-by-destination-country'
const COUNTS_BY_DESTINATION_ISP_COUNTRY_PATH = '/reports/dns-queries/counts-by-destination-isp'

function getParamsAndUrl(type, queryParams) {
  const params = generateQueryParams(queryParams)

  const url = {
    [MostActiveType.SERVICES]: SERVICES_PIE_CHART_PATH,
    [MostActiveType.FILTERS]: FILTERS_PIE_CHART_PATH,
    [MostActiveType.LOCATIONS]: LOCATIONS_PIE_CHART_PATH,
    [VerdictType.BLOCKED]: BLOCKED_BY_DOMAIN_DATA_PATH,
    [VerdictType.REDIRECTED]: REDIRECTED_BY_DOMAIN_DATA_PATH,
    [VerdictType.BYPASSED]: BYPASSED_BY_DOMAIN_DATA_PATH,
    [TrafficType.SOURCE]: COUNTS_BY_SOURCE_COUNTRY_PATH,
    [TrafficType.DESTINATION]: COUNTS_BY_DESTINATION_COUNTRY_PATH,
    [TrafficType.ISP]: COUNTS_BY_DESTINATION_ISP_COUNTRY_PATH,
  }[type]

  return { url, params }
}

export const analyticsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getTimeSeries: builder.query({
      query: ({
        region,
        startTs,
        endTs,
        granularity,
        filter,
        service,
        via,
        deviceId,
        clientId,
      }: CommonParams & {
        region: string
        granularity: TimeGranularity
        filter?: string
        service?: string
        via?: string
      }) => {
        const params = generateQueryParams({
          startTs,
          endTs,
          granularity,
          filter,
          service,
          via,
          deviceId,
          clientId,
        })

        return getQueryArgs(
          `https://${region}.${ANALYTICS_BASE_URL}${TIME_SERIES_PATH}&${params.toString()}`,
        )
      },
      transformResponse: (response: ApiResponse<AnalyticsTimeSeriesData>) => response.body,
      transformErrorResponse: transformStatsErrorResponse,
    }),
    getServicesPieChart: builder.query({
      query: ({
        region,
        type,
        startTs,
        endTs,
        deviceId,
        clientId,
      }: CommonParams & {
        region: string
        type: MostActiveType
      }) => {
        const { params, url } = getParamsAndUrl(type, {
          startTs,
          endTs,
          deviceId,
          clientId,
        })

        return getQueryArgs(`https://${region}.${ANALYTICS_BASE_URL}${url}?&${params.toString()}`)
      },
      transformResponse: (response: ApiResponse<AnalyticsPieChartData>) => response.body,
      transformErrorResponse: transformStatsErrorResponse,
    }),
    getFiltersPieChart: builder.query({
      query: ({
        region,
        type,
        startTs,
        endTs,
        deviceId,
        clientId,
      }: CommonParams & {
        region: string
        type: MostActiveType
      }) => {
        const { params, url } = getParamsAndUrl(type, {
          startTs,
          endTs,
          deviceId,
          clientId,
        })

        return getQueryArgs(`https://${region}.${ANALYTICS_BASE_URL}${url}?&${params.toString()}`)
      },
      transformResponse: (response: ApiResponse<AnalyticsPieChartData>) => response.body,
      transformErrorResponse: transformStatsErrorResponse,
    }),
    getLocationsPieChart: builder.query({
      query: ({
        region,
        type,
        startTs,
        endTs,
        deviceId,
        clientId,
      }: CommonParams & {
        region: string
        type: MostActiveType
      }) => {
        const { params, url } = getParamsAndUrl(type, {
          startTs,
          endTs,
          deviceId,
          clientId,
        })

        return getQueryArgs(`https://${region}.${ANALYTICS_BASE_URL}${url}?&${params.toString()}`)
      },
      transformResponse: (response: ApiResponse<AnalyticsPieChartData>) => response.body,
      transformErrorResponse: transformStatsErrorResponse,
    }),
    getPieChartDomains: builder.query({
      query: ({
        region,
        type,
        startTs,
        endTs,
        pk,
        deviceId,
        clientId,
      }: CommonParams & {
        region: string
        type: MostActiveType
        pk: string
      }) => {
        const { params, url } = getParamsAndUrl(type, {
          startTs,
          endTs,
          deviceId,
          clientId,
        })

        return getQueryArgs(`https://${region}.${ANALYTICS_BASE_URL}${url}/${pk}?${params}`)
      },
      transformResponse: (response: ApiResponse<AnalyticsPieChartData>) => response.body,
      transformErrorResponse: transformStatsErrorResponse,
    }),
    getBlockedDomains: builder.query({
      query: ({
        region,
        domainType,
        startTs,
        endTs,
        deviceId,
        clientId,
      }: CommonParams & {
        region: string
        domainType: VerdictType
      }) => {
        const { params, url } = getParamsAndUrl(domainType, {
          startTs,
          endTs,
          deviceId,
          clientId,
        })

        return getQueryArgs(`https://${region}.${ANALYTICS_BASE_URL}${url}&${params.toString()}`)
      },
      transformResponse: (response: ApiResponse<AnalyticsPieChartData>) => response.body,
      transformErrorResponse: transformStatsErrorResponse,
    }),
    getBypassedDomains: builder.query({
      query: ({
        region,
        domainType,
        startTs,
        endTs,
        deviceId,
        clientId,
      }: CommonParams & {
        region: string
        domainType: VerdictType
      }) => {
        const { params, url } = getParamsAndUrl(domainType, {
          startTs,
          endTs,
          deviceId,
          clientId,
        })

        return getQueryArgs(`https://${region}.${ANALYTICS_BASE_URL}${url}&${params.toString()}`)
      },
      transformResponse: (response: ApiResponse<AnalyticsPieChartData>) => response.body,
      transformErrorResponse: transformStatsErrorResponse,
    }),
    getRedirectedDomains: builder.query({
      query: ({
        region,
        domainType,
        startTs,
        endTs,
        deviceId,
        clientId,
      }: CommonParams & {
        region: string
        domainType: VerdictType
      }) => {
        const { params, url } = getParamsAndUrl(domainType, {
          startTs,
          endTs,
          deviceId,
          clientId,
        })

        return getQueryArgs(`https://${region}.${ANALYTICS_BASE_URL}${url}&${params.toString()}`)
      },
      transformResponse: (response: ApiResponse<AnalyticsPieChartData>) => response.body,
      transformErrorResponse: transformStatsErrorResponse,
    }),
    getStatEndpoints: builder.query({
      query: () => getQueryArgs('/analytics/endpoints'),
      transformErrorResponse,
      transformResponse: (response: ApiResponse<{ endpoints: StatEndpointInfo[] }>) =>
        response.body,
    }),
    getStatLevels: builder.query({
      query: () => getQueryArgs('/analytics/levels'),
      transformErrorResponse,
      transformResponse: (response: ApiResponse<{ levels: StatLevelInfo[] }>) => response.body,
    }),
    getSources: builder.query({
      query: ({
        region,
        type,
        startTs,
        endTs,
        deviceId,
        clientId,
      }: CommonParams & {
        region: string
        type: TrafficType
      }) => {
        const { params, url } = getParamsAndUrl(type, {
          startTs,
          endTs,
          deviceId,
          clientId,
        })

        return getQueryArgs(`https://${region}.${ANALYTICS_BASE_URL}${url}?&${params.toString()}`)
      },
      transformResponse: (response: ApiResponse<AnalyticsPieChartData>) => response.body,
      transformErrorResponse: transformStatsErrorResponse,
    }),
    getSourcesByCountry: builder.query({
      query: ({
        region,
        type,
        startTs,
        endTs,
        deviceId,
        clientId,
        country,
      }: CommonParams & {
        region: string
        type: TrafficType
        country: string
      }) => {
        const { params, url } = getParamsAndUrl(type, {
          startTs,
          endTs,
          deviceId,
          clientId,
        })

        return getQueryArgs(
          `https://${region}.${ANALYTICS_BASE_URL}${url}/${country}?&${params.toString()}`,
        )
      },
      transformResponse: (response: ApiResponse<AnalyticsPieChartData>) => response.body,
      transformErrorResponse: transformStatsErrorResponse,
    }),
    getDestinations: builder.query({
      query: ({
        region,
        type,
        startTs,
        endTs,
        deviceId,
        clientId,
      }: CommonParams & {
        region: string
        type: TrafficType
      }) => {
        const { params, url } = getParamsAndUrl(type, {
          startTs,
          endTs,
          deviceId,
          clientId,
        })

        return getQueryArgs(`https://${region}.${ANALYTICS_BASE_URL}${url}?&${params.toString()}`)
      },
      transformResponse: (response: ApiResponse<AnalyticsPieChartData>) => response.body,
      transformErrorResponse: transformStatsErrorResponse,
    }),
    getDestinationsByCountry: builder.query({
      query: ({
        region,
        type,
        startTs,
        endTs,
        deviceId,
        clientId,
        country,
      }: CommonParams & {
        region: string
        type: TrafficType
        country: string
      }) => {
        const { params, url } = getParamsAndUrl(type, {
          startTs,
          endTs,
          deviceId,
          clientId,
        })

        return getQueryArgs(
          `https://${region}.${ANALYTICS_BASE_URL}${url}/${country}?&${params.toString()}`,
        )
      },
      transformResponse: (response: ApiResponse<AnalyticsPieChartData>) => response.body,
      transformErrorResponse: transformStatsErrorResponse,
    }),
    getDestinationIsps: builder.query({
      query: ({
        region,
        type,
        startTs,
        endTs,
        deviceId,
        clientId,
      }: CommonParams & {
        region: string
        type: TrafficType
      }) => {
        const { params, url } = getParamsAndUrl(type, {
          startTs,
          endTs,
          deviceId,
          clientId,
        })

        return getQueryArgs(`https://${region}.${ANALYTICS_BASE_URL}${url}?&${params.toString()}`)
      },
      transformResponse: (response: ApiResponse<AnalyticsPieChartData>) => response.body,
      transformErrorResponse: transformStatsErrorResponse,
    }),
    getDestinationIspsByIsp: builder.query({
      query: ({
        region,
        type,
        startTs,
        endTs,
        deviceId,
        clientId,
        isp,
      }: CommonParams & {
        region: string
        type: TrafficType
        isp: string
      }) => {
        const { params, url } = getParamsAndUrl(type, {
          startTs,
          endTs,
          deviceId,
          clientId,
        })

        return getQueryArgs(
          `https://${region}.${ANALYTICS_BASE_URL}${url}/isp?isp=${encodeURIComponent(
            isp,
          )}&${params.toString()}`,
        )
      },
      transformResponse: (response: ApiResponse<AnalyticsPieChartData>) => response.body,
      transformErrorResponse: transformStatsErrorResponse,
    }),
  }),
})

export const {
  useGetTimeSeriesQuery,
  useGetBlockedDomainsQuery,
  useGetBypassedDomainsQuery,
  useGetRedirectedDomainsQuery,
  useGetFiltersPieChartQuery,
  useGetServicesPieChartQuery,
  useGetLocationsPieChartQuery,
  useGetPieChartDomainsQuery,
  useLazyGetPieChartDomainsQuery,
  useGetStatEndpointsQuery,
  useGetStatLevelsQuery,
  useGetSourcesQuery,
  useLazyGetSourcesByCountryQuery,
  useGetDestinationsQuery,
  useLazyGetDestinationsByCountryQuery,
  useGetDestinationIspsQuery,
  useLazyGetDestinationIspsByIspQuery,
} = analyticsApi
