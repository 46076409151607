import AttachedIcon from 'images/barry/attach-icon.svg'
import React, { ReactElement, useState } from 'react'
import { usePreAuthTokenMutation, useSendSupportTicketMutation } from 'store/api/support/support'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { Page, switchPage } from 'store/barry/barry'
import { Flex, Spinner, Text } from 'theme-ui'
import ChatBackground from 'images/barry/chat-background.svg'
import SupportTicketInput from './BarryChatComponents/SupportTicketInput'
import useGetUser from 'components/Dashboard/utils/useGetUser'
import { Button, Svg } from 'ui'

const containerPaddingX = '1.5rem'

export default function SubmitTicketView(): ReactElement {
  const dispatch = useAppDispatch()
  const { messages } = useAppSelector(x => x.barry.message)
  const { data: userData } = useGetUser()
  const [email, setEmail] = useState(userData?.email || '')
  const [comments, setComments] = useState('')
  const [
    sendSupportTicket,
    {
      isError: isSendSupportTicketError,
      isUninitialized: isSendSupportTicketUninitialized,
      isLoading: isSendSupportTicketLoading,
    },
  ] = useSendSupportTicketMutation()
  const [
    getPreAuthToken,
    { isError: isPreAuthError, isLoading: isPreAuthLoading, isSuccess: isPreAuthSuccess },
  ] = usePreAuthTokenMutation()

  const shouldDisplayErrorMessage = isSendSupportTicketError || isPreAuthError
  const isRequestLoading =
    isSendSupportTicketLoading ||
    isPreAuthLoading ||
    ((isPreAuthSuccess || isPreAuthError) && isSendSupportTicketUninitialized)

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      }}
      as="form"
      onSubmit={e => {
        e.preventDefault()
        if (!email) {
          return
        }

        getPreAuthToken({ userEmail: email })
          .unwrap()
          .then(payload => {
            if (!!payload) {
              sendSupportTicket({
                email: email,
                subject: `Barry Support Ticket from: ${email}`,
                chatlog: messages,
                ...(comments && { message: comments }),
                channel: 'chatbot',
                token: payload.token,
              })
            }
          })
      }}
    >
      <Flex
        sx={{
          zIndex: 'zIndex-1',
          position: 'absolute',
          pointerEvents: 'none',
        }}
      >
        <Svg svg={ChatBackground} />
      </Flex>
      <Flex
        data-testid="describe-issue-view"
        sx={{
          width: '100%',
          height: '100%',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Flex sx={{ width: '100%', flexDirection: 'column', px: containerPaddingX }}>
          <Flex
            sx={{
              width: '100%',
              flexDirection: 'column',
              backgroundColor: 'darkJungleGreen',
              height: '4.8rem',
              border: ({ colors }) => `1px solid ${colors?.borderFaint}`,
              borderRadius: '1rem',
              pointerEvents: 'none',
              mt: ['2.4rem', '1.6rem'],
              justifyContent: 'center',
            }}
            data-testid="chatlog-attached-button"
          >
            <Flex
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                gap: '1.0rem',
                width: '100%',
              }}
            >
              <Svg svg={AttachedIcon} />
              <Text variant="size14Weight700" sx={{ color: 'title' }}>
                Chatlog Attached
              </Text>
            </Flex>
          </Flex>
          <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
            <Text
              variant="size28Weight600"
              sx={{
                color: 'title',
                mt: ['3.2rem', '2.4rem'],
                mb: 0,
              }}
            >
              Talk to a human
            </Text>
            <Text
              sx={{
                color: shouldDisplayErrorMessage ? 'orangeRed' : 'body',
                mt: '0.8rem',
                mb: 0,
                maxWidth: '29.6rem',
                fontSize: '1.5rem',
                fontWeight: 'normal',
                textAlign: 'center',
              }}
            >
              {shouldDisplayErrorMessage
                ? 'Something went wrong, please try sending it again.'
                : "Fill this form out and we'll get back to you as soon as possible by email"}
            </Text>
          </Flex>
          <Flex
            sx={{ mt: ['2.4rem', '1.6rem'], width: '100%', gap: '2rem', flexDirection: 'column' }}
          >
            {' '}
            {/**
             * TODO: Using autofill for the email on chrome at least fills in string with a fontSize of 17px
             */}
            <SupportTicketInput
              label="Email"
              inputTestId="ticket_flow_email"
              onChange={({ target }) => setEmail(target.value)}
              value={email}
              name="email"
              required={true}
              type="email"
            />
            <SupportTicketInput
              label="Comments (optional)"
              required={false}
              inputTestId="ticket_flow_comments"
              onChange={({ target }) => setComments(target.value)}
              value={comments}
              name="comments"
              type="text"
            />
          </Flex>
        </Flex>
        <Flex
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 'zIndex3',
            backgroundColor: 'smokyBlack',
            height: '7.3rem',
            borderTop: '1px solid',
            borderColor: 'mattBlue50',
            width: '100%',
          }}
        >
          <Flex
            sx={{
              mx: `${containerPaddingX}`,
              width: '100%',
            }}
          >
            <Button
              variant="transparent"
              sx={{
                fontWeight: 'bold',
                color: 'body',
                minWidth: '50%',
                width: '50%',
                fontSize: '1.8rem',
              }}
              onClick={() => dispatch(switchPage(Page.CHAT_PAGE))}
            >
              Back
            </Button>
            <Button
              variant="gradientButton"
              color="title"
              sx={{
                color: 'white',
                minWidth: '50%',
                height: '3.9rem',
                py: 0,
                zIndex: 'zIndex2',
                '& svg': {
                  mt: '0.4rem',
                },
              }}
              type="submit"
              data-testid="ticket_flow_submit"
              disabled={isRequestLoading}
            >
              {isRequestLoading ? (
                <Spinner size={24} duration={900} color="body" />
              ) : (
                <Text
                  variant="size17Weight700"
                  sx={{
                    pb: '0.2rem',
                  }}
                >
                  Send
                </Text>
              )}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
