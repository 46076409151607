import React, { ReactElement } from 'react'
import { appStore } from 'store'
import BarryDialog from 'components/Barry/BarryComponents/BarryDialog'

export default function Barry(): ReactElement {
  const isCypress =
    typeof window !== 'undefined' &&
    // @ts-ignore
    !!window?.Cypress

  return (
    <>
      {isCypress ? (
        appStore.getState()?.persistData?.shouldLoadBarryE2eTest ? (
          <BarryDialog />
        ) : null
      ) : (
        <BarryDialog />
      )}
    </>
  )
}
