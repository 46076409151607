import React, { PropsWithChildren, ReactElement } from 'react'
import { Divider, Flex, Text } from 'theme-ui'
import CopyToClipboard from 'components/CopyToClipboard'
import { resolverText } from 'components/SetupGuide/ResolverTabs/Components/ResolverTabTitle'
import { Accordion } from 'ui'
import { ResolverType } from 'components/SetupGuide/SetupGuide.interface'

const bootstrapIps = {
  [ResolverType.DOH]: ['76.76.2.22', '76.76.10.22'],
  [ResolverType.DOT]: ['2606:1a40::22', '2606:1a40:1::22'],
}

export interface SecureResolverData {
  resolverId?: string
  doh?: string
  dot?: string
}
export default function SecureResolver({
  isResolverTypeHighlighted,
  canCopy,
  address,
  shouldShowFullAddress,
  type,
}: {
  isResolverTypeHighlighted: boolean
  address: string
  canCopy: boolean
  shouldShowFullAddress?: boolean
  type?: string
}): ReactElement {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        minWidth: '100%',
        mb: '1.6rem',
      }}
    >
      <Flex
        sx={{
          minWidth: '100%',
          flexShrink: 0,
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          borderRadius: '0.8rem',
          px: '1.6rem',
          py: '1.3rem',
          color: isResolverTypeHighlighted ? 'greenApple' : 'white50',
          backgroundColor: isResolverTypeHighlighted ? 'greenApple15' : 'dialogBodyBg',
          border: '1px solid',
          borderColor: isResolverTypeHighlighted ? 'greenApple' : 'white15',
          flexDirection: 'column',
        }}
      >
        {type && (
          <Text
            variant="cardLabel"
            sx={{
              whiteSpace: 'nowrap',
              flexShrink: 0,
              mr: '0.8rem',
              color: 'textColor',
              fontSize: ['1.6rem', '1.8rem'],
            }}
          >
            {resolverText[type]}
          </Text>
        )}
        <Address
          shouldShowFullAddress={shouldShowFullAddress}
          address={address}
          canCopy={canCopy}
          isResolverTypeHighlighted={isResolverTypeHighlighted}
        />
        {bootstrapIps[type as ResolverType] && (
          <>
            <Divider sx={{ width: '100%', height: '1px', color: 'white10', my: '1.6rem' }} />
            <BootstrapIp>
              {bootstrapIps[type as ResolverType].map(address => (
                <Address
                  key={address}
                  address={address}
                  canCopy={canCopy}
                  isResolverTypeHighlighted={isResolverTypeHighlighted}
                />
              ))}
            </BootstrapIp>
          </>
        )}
      </Flex>
    </Flex>
  )
}

function BootstrapIp({ type, children }: PropsWithChildren<{ type?: string }>) {
  return (
    <Accordion
      data-testid="Bootstrap IPs"
      id={type || ''}
      title="Bootstrap IPs"
      containerStyle={{
        minWidth: '100%',
      }}
      buttonStyle={{
        justifyContent: 'space-between',
        px: 0,
        py: 0,
        width: '100%',
      }}
      titleStyle={{ minWidth: '100%', fontSize: ['1.6rem', '1.8rem'], fontWeight: 600 }}
      openTitleStyle={{ minWidth: '100%', fontSize: ['1.6rem', '1.8rem'], fontWeight: 600 }}
    >
      <Flex sx={{ width: '100%', flexDirection: 'column' }}>{children}</Flex>
    </Accordion>
  )
}

function Address({
  shouldShowFullAddress,
  address,
  canCopy,
  isResolverTypeHighlighted,
}: {
  shouldShowFullAddress?: boolean
  address: string
  canCopy: boolean
  isResolverTypeHighlighted: boolean
}) {
  return (
    <Flex sx={{ width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
      <Text
        variant="address"
        sx={{
          color: 'textColor',
          mr: '2rem',
          fontSize: '1.6rem',
          overflow: 'hidden',
          textAlign: 'left',
          ...(shouldShowFullAddress
            ? { wordWrap: 'break-word' }
            : {
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                maxWidth: '100%', // to enable the textoverflow
              }),
          minWidth: '20rem',
        }}
        tabIndex={0}
      >
        {address}
      </Text>
      {canCopy && (
        <CopyToClipboard
          data={address}
          smallIcon
          ariaLabelText="copy resolver to clipboard"
          fillSvg={isResolverTypeHighlighted ? 'greenApple' : 'textColor'}
          sx={{ px: 0 }}
        />
      )}
    </Flex>
  )
}
