import React, { ReactElement } from 'react'
import { Flex, Text } from 'theme-ui'
import CloseButtonPopUp from 'images/barry/closebutton.svg'
import ChatBubbleEdge from 'images/barry/bubblebox.svg'

export enum BarryHelloMessagAction {
  HIDE = 'show',
  OPEN = 'open',
}

export default function BarryPopUp({
  barryMessage,
  handleBarryHelloMessage,
}: {
  barryMessage?: string
  handleBarryHelloMessage: (action: BarryHelloMessagAction) => void
}): ReactElement {
  return (
    <>
      <Flex
        sx={{
          width: '20rem',
          height: 'auto',
          zIndex: 'zIndex999',
          position: 'absolute',
          bottom: '9.4rem',
          right: ['4.0rem', '4.8rem'],
          boxShadow: '0px 1px 0.2px 0px rgba(255, 255, 255, 0.1) inset',
          backgroundColor: 'spaceCadet',
          borderRadius: '1.2rem 1.2rem 0rem 1.2rem',
          flexDirection: 'column',
          p: '1.2rem',
        }}
      >
        <Flex
          data-testId="popup-close"
          sx={{
            boxShadow: '0px 1px 0.2px 0px rgba(255, 255, 255, 0.1) inset',
            backgroundColor: 'spaceCadet',
            p: '0.3rem',
            borderTopLeftRadius: '10rem',
            borderTopRightRadius: '10rem',
            borderBottomLeftRadius: '10rem',
            borderBottomRightRadius: '0rem',
            top: '-1.1rem',
            right: '0rem',
            position: 'absolute',
          }}
          onClick={() => {
            handleBarryHelloMessage(BarryHelloMessagAction.HIDE)
          }}
        >
          <CloseButtonPopUp />
        </Flex>
        <Flex
          data-testId="popup-message"
          onClick={() => {
            handleBarryHelloMessage(BarryHelloMessagAction.OPEN)
          }}
        >
          <Text
            variant="size12Weight400"
            sx={{
              color: 'title',
            }}
          >
            {barryMessage}
          </Text>
        </Flex>
      </Flex>
      <Flex
        sx={{
          position: 'absolute',
          bottom: '8.4rem',
          right: ['4.0rem', '4.8rem'],
          zIndex: 100,
        }}
      >
        <ChatBubbleEdge />
      </Flex>
    </>
  )
}
